import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography, InputBase, useMediaQuery } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { TFunction } from "next-i18next";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { Router } from "../../i18n";

type NavbarWithSearchType = {
  title?: any;
  onTypeSearch?: any;
  onClick?: any;
  placeholder?: string;
  tabs?: boolean;
  route?: any;
  withTimeout?: boolean;
  backRoute?: string;
  readonly t: TFunction;
  disableMargin?: boolean;
  disableInput?: boolean;
};

type SmallNavbarWithSearchType = {
  placeholder?: string;
  onTypeSearch?: any;
  onClick?: any;
  keyword?: any;
  onClear?: any;
  setIsLoading?: any;
  disableMargin?: boolean;
  readonly t: TFunction;
};

const NavbarWithSearch = (props: NavbarWithSearchType) => {
  const router = Router;
  const {
    title,
    onTypeSearch,
    onClick,
    placeholder,
    tabs,
    withTimeout,
    backRoute,
    t,
    disableInput,
  } = props;
  const classes = useStyles(props);
  const [search, setSearch] = useState("");

  onTypeSearch
    ? withTimeout
      ? useEffect(() => {
          if (search !== "") {
            let timer = setTimeout(() => {
              onTypeSearch(search);
            }, 500);
            return () => clearTimeout(timer);
          } else {
            onTypeSearch();
          }
        }, [search])
      : useEffect(() => {
          onTypeSearch(search);
        }, [search])
    : null;

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                backRoute ? router.push(backRoute) : router.back()
              }
            >
              <ArrowBackIcon />
            </div>
            <Typography className={classes.title}>{title}</Typography>
          </div>

          <div className={classes.searchWrapper} onClick={onClick}>
            <div className={classes.search}>
              {onTypeSearch ? (
                <>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder={
                      placeholder ? placeholder : t("find-hotel-location")
                    }
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    value={search}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  {search.length !== 0 && (
                    <div className={classes.closeIcon}>
                      <CloseIcon onClick={() => setSearch("")} />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  {disableInput === true ? (
                    <InputBase
                      placeholder={placeholder}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      disabled
                    />
                  ) : (
                    <InputBase
                      placeholder={placeholder}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SmallNavbarWithSearch = (props: SmallNavbarWithSearchType) => {
  const {
    onTypeSearch,
    onClick,
    placeholder,
    keyword,
    onClear,
    t,
    setIsLoading,
  } = props;
  const classes = useStyles(props);
  const router = Router;
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search !== "") {
      setIsLoading(false);
      onTypeSearch(search);
    } else {
      onTypeSearch();
    }
  }, [search]);

  useEffect(() => {
    setSearch(keyword);
  }, [keyword]);

  const handleClean = () => {
    setSearch("");
    onClear();
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => router.back()}
            >
              <ArrowBackIcon />
            </div>

            <div
              className={classes.searchWrapper}
              onClick={onClick}
              style={{ padding: "0px 0px 0px 16px" }}
            >
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder={
                    placeholder ? placeholder : t("find-hotel-location")
                  }
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  value={search}
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleChange}
                />
                {search.length !== 0 && (
                  <div
                    style={{
                      marginRight: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CloseIcon onClick={handleClean} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type NavbarDefaultType = {
  title?: any;
  onClickBack?: () => void;
  route?: any;
  disableMargin?: boolean;
  history?: boolean;
  withLoading?: boolean;
  tabs?: boolean;
  backRoute?: string;
  withoutBack?: boolean;
};

const NavbarDefault = (props: NavbarDefaultType) => {
  const router = Router;
  const {
    title,
    onClickBack,
    route,
    disableMargin,
    history,
    withoutBack,
    tabs,
  } = props;
  const classes = useStyles(props);
  const small = useMediaQuery("(max-width:475px)");
  const extraSmall = useMediaQuery("(width:320px)");

  return (
    <>
      <div
        className={classes.root}
        style={{
          boxShadow:
            !title || tabs ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.04)",
        }}
      >
        {!withoutBack && (
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              route
                ? router.push(route)
                : onClickBack
                ? onClickBack()
                : router.back()
            }
          />
        )}
        <div>
          <Typography
            className={
              small
                ? classes.smallTitle
                : extraSmall
                ? classes.extraSmallTitle
                : classes.title
            }
            style={{ paddingLeft: withoutBack ? 0 : 20 }}
          >
            {title}
          </Typography>
        </div>
        {history && (
          <div
            style={{ position: "absolute", right: 20, cursor: "pointer" }}
            onClick={() => Router.push("/orders-history")}
          >
            <img src="/img/orderHistory.svg" />
          </div>
        )}
      </div>
      {!disableMargin && <div style={{ paddingTop: 64 }} />}
    </>
  );
};

const NavbarWithoutArrowBack = (props: NavbarDefaultType) => {
  const { title, disableMargin, history } = props;
  const classes = useStyles(props);
  return (
    <>
      <div
        className={classes.root}
        style={{
          boxShadow: !title ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.04)",
        }}
      >
        <div>
          <Typography className={classes.removePaddingLeftTitle}>
            {title}
          </Typography>
        </div>
        {history && (
          <div
            style={{ position: "absolute", right: 20, cursor: "pointer" }}
            onClick={() => Router.push("/orders-history")}
          >
            <img src="/img/orderHistory.svg" />
          </div>
        )}
      </div>
      {!disableMargin && <div style={{ paddingTop: 64 }} />}
    </>
  );
};

type NavbarProps = {
  withSearch?: boolean;
  withClickableSearch?: boolean;
  smallNavbarWithSearch?: boolean;
  withoutArrowBack?: boolean;
  title?: string;
  onTypeSearch?: any;
  onClick?: any;
  placeholder?: any;
  keyword?: any;
  tabs?: boolean;
  route?: any;
  withTimeout?: boolean;
  disableMargin?: boolean;
  history?: boolean;
  onClear?: () => void;
  withLoading?: boolean;
  backRoute?: string;
  setIsLoading?: () => void;
  t: TFunction;
  onClickBack?: () => void;
  disableInput?: boolean;
  withoutBack?: boolean;
};

const Navbar = (props: NavbarProps) => {
  const {
    title,
    onTypeSearch,
    withSearch,
    withClickableSearch,
    withoutArrowBack,
    smallNavbarWithSearch,
    onClick,
    placeholder,
    keyword,
    tabs,
    onClickBack,
    disableMargin,
    history,
    route,
    withTimeout,
    t,
    withLoading,
    onClear,
    backRoute,
    setIsLoading,
    disableInput,
    withoutBack,
  } = props;
  return (
    <>
      {withSearch ? (
        <NavbarWithSearch
          backRoute={backRoute}
          title={title}
          onTypeSearch={onTypeSearch}
          onClick={onClick}
          placeholder={placeholder}
          withTimeout={withTimeout}
          disableMargin={disableMargin}
          t={t}
          disableInput={disableInput}
        />
      ) : withoutArrowBack ? (
        <NavbarWithoutArrowBack title={title} />
      ) : withClickableSearch ? (
        <NavbarWithSearch
          title={title}
          onClick={onClick}
          placeholder={placeholder}
          disableMargin={disableMargin}
          backRoute={backRoute}
          t={t}
        />
      ) : smallNavbarWithSearch ? (
        <SmallNavbarWithSearch
          onClick={onClick}
          placeholder={placeholder}
          onTypeSearch={onTypeSearch}
          disableMargin={disableMargin}
          keyword={keyword}
          t={t}
          onClear={onClear}
          setIsLoading={setIsLoading}
        />
      ) : (
        <NavbarDefault
          title={title}
          onClickBack={onClickBack}
          tabs={tabs}
          route={route}
          history={history}
          disableMargin={disableMargin}
          withLoading={withLoading}
          backRoute={backRoute}
          withoutBack={withoutBack}
        />
      )}
    </>
  );
};

export default Navbar;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: 20,
      background: "#ffffff",
      position: "fixed",
      top: 0,
      width: "100%",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.04)",
      zIndex: 10,
      margin: 0,
      alignItems: "center",
      maxWidth: 444,
    },
    title: {
      paddingLeft: 20,
      fontWeight: "bold",
      fontSize: 16,
      width: 375,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    smallTitle: {
      paddingLeft: 20,
      fontWeight: "bold",
      fontSize: 14,
      width: 300,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    extraSmallTitle: {
      paddingLeft: 20,
      fontWeight: "bold",
      fontSize: 12,
      width: 250,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    removePaddingLeftTitle: {
      fontWeight: "bold",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: `#f1f5f9`,
      marginLeft: 0,
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },
    searchWrapper: {
      paddingTop: 20,
      backgroundColor: "#ffffff",
      width: "100%",
      maxWidth: 444,
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#a0a4a8",
    },
    closeIcon: {
      color: "#a0a4a8",
      marginRight: 10,
      cursor: "pointer",
      display: "flex",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      padding: theme.spacing(1.8, 1.8, 1.8, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "30ch",
      },
      fontSize: 14,
    },
  })
);
